<script>
import store from '@/infrastructure/store'
import FriendCard from '../components/FriendCard'
import useRealtimeSubscriptions from '@/infrastructure/realtime/realtimeSubscriptions'
import useRealtimeGroups from '@/infrastructure/realtime/realtimeGroups'
import FriendItemsList from '@/features/items/components/FriendItemsList'

export default {
  components: {
    FriendItemsList,
    FriendCard,
  },

  props: {
    userId: { type: String, required: true },
  },

  setup(props) {
    const { subscribeToGroup } = useRealtimeGroups()
    subscribeToGroup('FriendItems', [props.userId])

    const onFriendItemUpdated = (itemId) => store.dispatch('items/fetchFriendItem', { itemId: itemId })

    const { subscribeToRealtimeEvent } = useRealtimeSubscriptions()
    subscribeToRealtimeEvent('FriendItem:Claimed', onFriendItemUpdated)
    subscribeToRealtimeEvent('FriendItem:Unclaimed', onFriendItemUpdated)
    subscribeToRealtimeEvent('FriendItem:ContributionAdded', onFriendItemUpdated)
    subscribeToRealtimeEvent('FriendItem:ContributionRemoved', onFriendItemUpdated)
    subscribeToRealtimeEvent('FriendItem:Archived', onFriendItemUpdated)
    subscribeToRealtimeEvent('FriendItem:Unarchived', onFriendItemUpdated)
  },

  data() {
    return {
      isLoadingFriend: false,
      isLoadingItems: false,
      highlightedItemScrollOffset: 80,
      highlightedItemId: undefined,
    }
  },

  computed: {
    authUserId: () => store.getters['auth/authUserId'],
    friend: (ctx) => store.getters['friends/getFriendByUserId'](ctx.userId),
    items: (ctx) => store.getters['items/getFriendArchivedItems'](ctx.userId),
  },

  watch: {
    friend(to) {
      if (to) {
        store.commit('setPageTitle', `${to.friendFullName} archived items`)
      }
    },
  },

  mounted() {
    if (this.$route.query.highlightedItemId) {
      console.debug('this.$route.query.highlightedItemId', this.$route.query.highlightedItemId)
      this.highlightedItemId = this.$route.query.highlightedItemId
    }

    this.loadFriend()
    this.loadItems()
  },

  methods: {
    async loadFriend() {
      this.isLoadingFriend = true
      try {
        await store.dispatch('friends/fetchFriendRequest', { friendUserId: this.userId })
      } finally {
        this.isLoadingFriend = false
      }
    },

    async loadItems() {
      this.isLoadingItems = true
      try {
        await store.dispatch('items/fetchFriendArchivedItems', { userId: this.userId })
      } finally {
        this.isLoadingItems = false
      }
    },
  },
}
</script>

<template>
  <div>
    <v-sheet v-if="!friend" class="pa-3">
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </v-sheet>

    <FriendCard
      v-else
      :loading="isLoadingFriend || isLoadingItems"
      :user-id="friend.friendUserId"
      :full-name="friend.friendFullName"
      :email="friend.friendEmail"
      :picture="friend.friendPicture"
      :status="friend.status"
    >
      <template v-slot:footer>
        <v-card-text>
          <div class="text-center">
            <v-chip label color="secondary">Archived items</v-chip>
          </div>
        </v-card-text>
      </template>
    </FriendCard>

    <div class="pt-3"></div>

    <template v-if="friend && items">
      <FriendItemsList
        :auth-user-id="authUserId"
        :friend-user-id="userId"
        :items="items"
        :highlighted-item-id="highlightedItemId"
        :highlighted-item-scroll-offset="highlightedItemScrollOffset"
        item-css-class="mb-3"
      />

      <v-card v-if="!items || items.length === 0" class="mb-3">
        <v-card-text>No items</v-card-text>
      </v-card>
    </template>
  </div>
</template>
